
$(document).ready ->
	app.init()

app =

	init: ->

		# Browsers
		#app.browsers()

		# Menú
		app.secretMenu.init()

		# Shares
		app.shares.init()

		# Tooltips
		app.tooltips()

		# Alertas
		app.alert.init()

		# Validación de formularios
		app.validation.form $("form.controls")

		# Loading
		app.loading.init()

		# Eventos en scroll
		app.scroll()

		# Plugins
		app.plugins.init()

		# Actions
		app.actions.init()

		# Fonts
		app.fonts.init()

#=include_tree app


app.actions =

	init: ->

		$("[data-goto]").click ->
			goto = $(this).attr("data-goto")
			to   = $(goto).offset().top - $("header").height()
			
			if goto == "#test-font-container"
				to = to + 12
				setTimeout ->
					$(".test-font-h1.live").focus()
				,500

			$("html,body,.secretmenu-container-front").animate
				scrollTop: to

			false




app.alert =

	init: ->
		app.alert.equidist()
		setTimeout ->
			app.alert.equidist()
		,100
		setTimeout ->
			app.alert.equidist()
		,1000
		$(window).resize ->
			app.alert.equidist()


		if $("[data-alert]").length

			$("a[data-alert]").live "click", ->
				element = $(this)
				app.alert.open
					title: element.attr("data-title")
					content: element.attr("data-content")
					accept: true
					cancel: true
					callback_true: ->
						location.href = element.attr("href")
				false

			$("[data-alert]").each ->
				element = $(this)
				if !element.is("a") && !element.is("button")
					app.alert.open
						title: element.attr("data-title")
						content: element.attr("data-content")
						accept: true
						cancel: true


	open: (options) ->

		title = ""
		content = ""
		buttons = ""
		close = ""

		if options.static == true
			alertlightclass    = ''
			options.close = false
		else
			alertlightclass = ' false'

		if options.alertclass
			alertclass = "alert-" + options.alertclass
		else
			alertclass = "alert-default"

		if options.title
			title = "<h2 class='alert-title'>" + options.title + "</h2>"

		if options.content
			content = "<div class='alert-content'>" + options.content + "</div>"

		if options.close == undefined
			options.close = true

		if options.close == true
			close = '<button class="alert-close false"><i class="fa fa-times"></i></button>'

		if options.buttons
			buttons += options.buttons + " "

		if options.cancel == true
			buttons += '<button class="button false">Cancelar</button> '

		if options.accept == true
			buttons += '<button class="button button-primary true">Aceptar</button> '

		if buttons
			buttons = '<div class="alert-buttons">'+buttons+'</div>'


		html =
			'<div class="alert '+alertclass+' in">'+
				'<div class="alert-light '+alertlightclass+'"></div>'+
				'<div class="alert-box equidist">'+
					'<div class="alert-inner">'+
						close +
						title +
						content +
						buttons +
					'</div>'+
				'</div>'+
			'</div>'


		$("body").append(html)
		$("body").addClass("alert-in")

		app.alert.equidist()


		$(".alert .true, .alert .false").unbind("click").bind "click", -> 

			alertorigin = $(this).closest(".alert")

			alertorigin.addClass("out")
			setTimeout ->
				alertorigin.remove()
				#alertorigin.removeClass("in out")
				$("body").removeClass("alert-in")
			,200

			if $(this).hasClass("true") && options.callback_true
				options.callback_true()

			if $(this).hasClass("false") && options.callback_false
				options.callback_false()

			return true

	closeall: ->
		$(".alert").addClass("out")
		$("body").removeClass("alert-in")

	removeall: ->
		$(".alert").addClass("out")
		setTimeout ->
			$(".alert").remove()
			$("body").removeClass("alert-in")
		,200

	equidist: ->
		$(".equidist").each ->
			_this = $(this)
			_left = (_this.parent().width() - _this.width()) / 2
			_left = 0 if _left < 0
			_top = (_this.parent().height() - _this.height()) / 2
			_top = 0 if _top < 0
			_this.css
			  left: _left + "px"
			  top: _top + "px"

	load: (href,cssclass="default",callback=false) ->
		$.ajax(
			url: href
			type: 'GET'
		).done (result) ->
			app.alert.open
				content: result
				alertclass: cssclass
			if callback
				callback()
			#app.plugins.relayout()




app.isMobile = ->
	if /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
		true
	else
		false

app.browsers = ->

	# Mobile
	if app.isMobile()
		$("body").addClass("is-mobile")

	# IE
	if $.browser.msie || navigator.appVersion.indexOf('Trident/')!=-1
		$("body").addClass("is-ie")
		$("body").addClass("is-ie"+$.browser.version)
		if parseInt($.browser.version) <= 7
			app.alert.open
				title: "Estás usando un navegador muy antiguo"
				content: "Actualiza tu navegador ahora y disfruta de una mejor experiencia en Falabella Novios."
				buttons: "<a href='http://browsehappy.com/?locale=es' target='_blank' class='button button-primary button-big'>Actualizar ahora</a>"
				static: true



app.cookie = 

	create: (name, value, days) ->
		if days
			date = new Date()
			date.setTime date.getTime() + (days * 24 * 60 * 60 * 1000)
			expires = "; expires=" + date.toGMTString()
		else
			expires = ""
		document.cookie = name + "=" + value + expires + "; path=/"

	read: (name) ->
		nameEQ = name + "="
		ca = document.cookie.split(";")
		i = 0

		while i < ca.length
			c = ca[i]
			c = c.substring(1, c.length)  while c.charAt(0) is " "
			return c.substring(nameEQ.length, c.length)  if c.indexOf(nameEQ) is 0
			i++
		null

	delete: (name) ->
		app.cookie.create name, "", -1




checkwidth_prev = false


app.fonts =

	init: ->

		app.fonts.tools.init()
		app.fonts.presentation()
		app.fonts.nav.init()

		app.fonts.instructions.init()


	add: (font,font_id) ->

		if font && font_id

			if !$("head").find('link[data-font-id="'+font_id+'"]').length
				$("head").append '<link href="'+$("body").attr("data-url")+'/wp-content/fonts/'+font_id+'/font.css" rel="stylesheet" type="text/css" data-font="'+font_id+'" />'


	loadFont: (fontdiv,callback=false) ->
		font    = fontdiv.attr("data-font")
		font_id    = fontdiv.attr("data-font-id")
		if font && font_id && font!=undefined
			app.fonts.add font, font_id
			fontdiv.css
				"font-family": font
			fontdiv.find("div,input").css
				"font-family": font
			#console.log "--- Fuente puesta"
			app.fonts.checkFont(fontdiv,font)


	searchLoadFont: ->
		foundfont = $(".font:not(.font-loaded)").eq(0)
		#console.log "*-- Fuente a cargar: "+ foundfont.attr("data-font")
		if foundfont.length
			app.fonts.loadFont foundfont, app.fonts.searchLoadFont


	checkFont: (fontdiv,font) ->
		$(".checkloadfont").remove()
		$("body").append("<span class='checkloadfont' style='position:absolute;top:-100px;left:0;background:#999;font-family:serif;'>abcijl!$%&/o0</span>")
		checkwidth_prev = false
		app.fonts.checkFontT(fontdiv,font)

	checkFontT: (fontdiv,font) ->

		#console.log "checkeando"

		checkdiv = $(".checkloadfont")
		checkwidth = checkdiv.width()

		$(".checkloadfont").css
			"font-family": font

		#console.log checkwidth + " vs " + checkwidth_prev

		if checkwidth!=checkwidth_prev && checkwidth_prev!=false
			fontdiv.addClass('font-loaded')
			#console.log "--- Fuente cargada"
			app.fonts.searchLoadFont()
		else
			#console.log "dsadsa"
			setTimeout ->
				app.fonts.checkFontT(fontdiv,font)
			,50

		checkwidth_prev = checkwidth






	nav:
		init: ->
			$(".single-font-navigation .nav").unbind("click").bind "click", ->
				app.fonts.nav.load $(this)
				false

		load: (element) ->

			url = element.attr("href").split(' ').join('%20');

			dir = false
			dir = "right" if element.hasClass("nav-right")
			dir = "left"  if element.hasClass("nav-left")

			#console.log url

			$(".single-font-header").removeClass "animation-right"
			$(".single-font-header").removeClass "animation-left"

			$(".single-font-header").addClass "animation-right-out" if dir=="left"
			$(".single-font-header").addClass "animation-left-out"  if dir=="right"

			$(".test-font").addClass "out"

			$(".gallery").addClass("out")


			setTimeout ->

				$.ajax(
					url: url
				).done (result) ->
					html = $(result)
					new_header = html.find(".single-font-header >")
					new_gallery = html.find(".gallery >")

					$(".single-font-header").html(new_header)
					$(".gallery").html(new_gallery).removeClass("out")

					$(".single-font-header").removeClass "animation-right-out"
					$(".single-font-header").removeClass "animation-left-out" 
					$(".single-font-header").addClass "animation-"+dir

					newfont = $("h1").attr("data-font")
					newfont_id = $("h1").attr("data-font-id")

					if newfont_id

						$(".test-font").attr "data-font", newfont
						$(".test-font").attr "data-font-id", newfont_id
						$(".test-font-h1, .test-font-p").css
							"font-family": newfont

						$(".test-font").removeClass("out").addClass "in"

						app.fonts.add newfont, newfont_id
						app.fonts.tools.textareaheight()
						setTimeout ->
							app.fonts.tools.textareaheight()
						,1000

						app.fonts.nav.init()
						app.actions.init()

			,500


	presentation: ->

		texts_default = [
			"Lorem ipsum dolor sit amet",
			"Repellendus, inventore, nemo.",
			"423-89(08)*2+83591",
			"Doloremque placeat cupiditate",
			"Amet quod sint adipisci.",
			"$%&*=?{+",
			"Itaque nihil officiis."
			"ABCDEFGHIJKLMNÑOPQRSTUVWXYZ"
		]

		$(".hola-bg").each ->
			div = $(this)
			texts = div.attr("data-texts")
			if texts
				texts = texts.split("||")
			else
				texts = texts_default

			#console.log texts
			rand = Math.floor((Math.random() * 10) + 1)

			i = 1
			for text in texts
				rand_size = Math.floor((Math.random() * 150) + 1)
				rand_top = i*10
				div.append "<div class='chao chao-"+i+"' style='font-size:"+rand_size+"px;top:"+rand_top+"%;'>"+text+"</div>"
				i++

			# Insert font
			font = div.parent().attr("data-font")
			font_id = div.parent().attr("data-font-id")
			app.fonts.add font, font_id
			div.css
				"font-family": font


		app.fonts.searchLoadFont()


		$(".font-big").keyup ->
			text = $(this).val()
			$(".font-big").each ->
				if !$(this).is(":focus")
					$(this).val text


	instructions:
		init: ->
			$(".instruction").each ->
				inst = $(this) 
				n = inst.attr("data-instruction")

				if !app.cookie.read "instruction-"+n

					inst.addClass("in")
					#console.log inst.parent().find("input,textarea")
					inst.parent().find("input,textarea").keyup ->
						setTimeout ->
							inst.addClass("out")
							app.cookie.create "instruction-"+n, "ok"
							setTimeout ->
								inst.remove()
							,500
						,500




	tools:
	
		init: ->

			# Height test
			$("#test-font-container").css
				"min-height": $(window).height() - $("header").height()
			$(window).resize ->
				$("#test-font-container").css
					"min-height": $(window).height() - $("header").height()


			# Set font

			font = $(".test-font").attr("data-font")
			font_id = $(".test-font").attr("data-font-id")
			
			app.fonts.add font, font_id
			$(".test-font-h1, .test-font-p").css
				"font-family": font

			# Events test

			app.fonts.tools.textareacopytext()
			$(".test-font-h1.live, .test-font-p.live").keyup ->
				app.fonts.tools.textareacopytext()

			$("body").click ->
				$(".test-font-group").removeClass "in"
				$(".tools").addClass "out"
				setTimeout ->
					$(".tools").removeClass "in out"
					$(".tools-group").removeClass "in"
				,500


			$(".tools").click (e) ->
				e.stopPropagation()
			$(".test-font-h1.live, .test-font-p.live").click (e) ->
				e.stopPropagation()

			$(".test-font-h1.live, .test-font-p.live").focus ->

				$(".tools").addClass "in"

				$(".test-font-group").removeClass "in"
				$(".tools-group").removeClass "in"

				test_group = $(this).closest(".test-font-group")
				test_group.addClass "in"
				$(".tools-group."+test_group.attr("data-tools")).addClass "in"
	
			setTimeout ->
				app.fonts.tools.textareaheight()
			,1000

			$(window).resize ->
				app.fonts.tools.textareaheight()



			# Set css

			$(".tool").each ->
				tool        = $(this)
				tool_to     = tool.attr("data-to")
				tool_css    = tool.attr("data-css")
				tool_init   = tool.attr("data-init")
				tool_select = tool.attr("data-select")

				# Set properties from cookie

				if app.cookie.read "color"
					$(".tool[data-css='color']").attr "data-init", app.cookie.read("color")
				if app.cookie.read "background-color"
					$(".tool[data-css='background-color']").attr "data-init", app.cookie.read("background-color")


				# Set css
				app.fonts.tools.insertcss(tool_to,tool_css,tool_init)

				# Set indicator
				app.fonts.tools.setindicator($(this),tool_init)

				# Set options for colors
				if tool_select
					tool_select_split = tool_select.split("|")
					tool.find(".tool-icon-color-inner").css
						'background-color': '#'+tool_init
					$.each tool_select_split, (k,tool_option) ->
						tool.find(".tool-select").append("<div class='tool-option' data-value='"+tool_option+"' style='background-color:#"+tool_option+";'><div class='tool-option-selected'></div></div>")


			# Events move bar

			click_active = false

			$(".tool .tool-bar").mousedown (e) ->
				app.fonts.tools.movebar($(this),e)
				click_active = true

			$(".tool .tool-bar").mouseup ->
				click_active = false

			$(".tool .tool-bar").mousemove (e) ->
				if click_active		
					app.fonts.tools.movebar($(this),e)


			# Events switch
			$(".tool[data-switch]").click ->
				tool     = $(this)
				tool_to  = tool.attr("data-to")
				tool_css = tool.attr("data-css")

				values = tool.attr("data-switch").split("|")
				value1 = values[0]
				value2 = values[1]

				tool.toggleClass("on")
				
				if tool.hasClass("on")
					app.fonts.tools.insertcss(tool_to,tool_css,value1)
				else
					app.fonts.tools.insertcss(tool_to,tool_css,value2)


			# Colores
			app.fonts.tools.colors.init()


		setindicator: (tool,value) ->

			tool_min = parseInt tool.attr("data-min")
			tool_max = parseInt tool.attr("data-max")

			if tool_max
	
				move = parseInt( ( value * 100 / (tool_max-tool_min) ) - ( tool_min * 100 / (tool_max-tool_min) ) )

				#invert
				move = 100 - move

				tool.find(".tool-indicator").css
					top: move + "%"



		movebar: (element,e) ->

			pos       = element.offset().top
			click     = e.pageY
			scroll    = $(window).scrollTop()
			height    = element.height()
			top       = pos - scroll
			click_bar = click - pos
			move      = click_bar * 100 / height

			element.find(".tool-indicator").css
				top: move + "%"

			tool = element.closest(".tool")
			tool_to = tool.attr("data-to")
			tool_css = tool.attr("data-css")
			tool_min = parseInt tool.attr("data-min")
			tool_max = parseInt tool.attr("data-max")

			tool_calculate = parseInt( (tool_max-tool_min) * move / 100 ) + tool_min

			#invert
			tool_calculate = tool_max - tool_calculate + tool_min

			#console.log tool_calculate+"px"

			app.fonts.tools.insertcss(tool_to,tool_css,tool_calculate)




		insertcss: (to,css,value) ->
			if css == "font-size"
				$(to).css "font-size": value+"px"
			if css == "line-height"
				$(to).css "line-height": value+"px"
			if css == "letter-spacing"
				$(to).css "letter-spacing": value+"px"
			if css == "word-spacing"
				$(to).css "word-spacing": value+"px"

			if css == "text-transform"
				$(to).css "text-transform": value
			if css == "font-weight"
				$(to).css "font-weight": value

			if css == "color"
				$(to).css "color": "#"+value
				app.cookie.create "color", value
			if css == "background-color"
				$(to).css "background-color": "#"+value
				app.cookie.create "background-color", value

			app.fonts.tools.textareaheight()


		textareaheight: ->

			height_h1 = $(".test-font-h1.ghost").height()
			$(".test-font-h1.live").css
				height: height_h1+"px"
			$(".test-font-h1.live").parent().find(".test-font-group-focus").css
				height: height_h1+"px"

			height_p = $(".test-font-p.ghost").height()
			$(".test-font-p.live").css
				height: height_p+"px"
			$(".test-font-p.live").parent().find(".test-font-group-focus").css
				height: height_p+"px"


		textareacopytext: ->

			$(".test-font-h1.ghost").html $(".test-font-h1.live").val()
			$(".test-font-p.ghost").html $(".test-font-p.live").val()
			app.fonts.tools.textareaheight()



		colors:
			init: ->
				$(".tools .tool-select .tool-option").click ->
					option     = $(this)
					tool       = option.closest(".tool")
					tool_to    = tool.attr("data-to")
					tool_css   = tool.attr("data-css")
					tool_value = option.attr("data-value")
					app.fonts.tools.insertcss(tool_to,tool_css,tool_value)

					tool.find(".tool-select .tool-option").removeClass("in")

					tool.find(".tool-icon-color-inner").css
						'background-color': '#'+tool_value 

					option.addClass("in")









app.loading =

	init: ->
		if $("[data-loading]").length
			app.loading.in()
		###
		app.loading.in()
		$("body").imagesLoaded ->
			app.loading.out()
		###

	in: (element) ->
		element = $("body") if !element
		element.append ''+
			'<div class="loading">'+
				'<div class="loading-icon">'+
					'<div class="loading-icon-circle"><div></div></div>'+
				'</div>'+
			'</div>'
	out: ->
		$(".loading").addClass "out"
		setTimeout ->
			$(".loading").remove()
		,500
		$("body").addClass("loaded")




app.plugins =

	init: ->


		# Isotope
		if $(".isotope").length
			isotope = $(".isotope").isotope()



	relayout: ->

		$("body").imagesLoaded ->
			app.alert.equidist()
			app.alert.equidist()
			if $(".isotope").length
				$(".isotope").isotope
					relayout: true





app.scroll = ->

	if !app.isMobile()
		scroll_prev = 0
		$(window).scroll ->

			# Esconder header
			scroll = $(window).scrollTop()
			height_window = $(window).height()
			height_body = $("body").height()

			if scroll > 50
				$("header").addClass "header-hide"
			else
				$("header").removeClass "header-hide"

			if scroll > 70
				$(".single-font-header").addClass("fixed")
			else
				$(".single-font-header").removeClass("fixed")


			scroll_prev = scroll


			# Mostrar en scroll

			if $(".displayscroll").length
				$(".displayscroll").each ->
					element = $(this)
					element_top = element.offset().top
					element_height = element.height()
					if scroll + height_window > element_height + element_top
						element.addClass "in"




app.secretMenu =

	init: ->

		# Compare URL in menu
		url = document.URL
		url_split = url.split("/")
		name_page = url_split[url_split.length-1]
		name_page_split = name_page.split("?") 
		name_page_clear = name_page_split[0]
		li = $(".secretmenu-content a[href='"+name_page_clear+"']").parent("li")
		li.addClass "current-item"
		li.parent().parent("li").addClass "current-item"

		# Desktop
		$(".secretmenu-content ul li a").each ->
			if $(this).parent().find("ul").length
				if !$(this).hasClass("secretmenu-parent")
					$(this).addClass("secretmenu-parent").prepend('<i class="fa fa-chevron-right"></i>')
					$(this).parent().find("ul").prepend '<li><a href="#" class="secretmenu-back"><i class="fa fa-chevron-left"></i> Atrás</a></li>'

		if $(".secretmenu-content ul li.current-item a.secretmenu-parent").length
			app.secretMenu.openLvlDesktop $(".secretmenu-content ul li.current-item a.secretmenu-parent")

		# Mobile

		$(".secretmenu-button").click ->
			if !$("body").hasClass("secretmenu-in")
				app.secretMenu.open $(".secretmenu-content").html()
			else
				app.secretMenu.close()
		$(".secretmenu-container-front").click ->
			if $("body").hasClass("secretmenu-in")
				app.secretMenu.close()
		true

	openLvlDesktop: (element) ->
		ul = element.parent().find("ul")
		ul.addClass("in")
		ul.find("a.secretmenu-back").unbind("click").bind "click", ->
			ul.addClass("out")
			setTimeout ->
				ul.removeClass("in out")
			,700
			false


	open: (html,children=false,direction="left") ->

		length    = $(".secretmenu").length + 1
		container = '<div class="secretmenu secretmenu-lvl-'+($(".secretmenu").length + 1)+'"></div>'
		direction = "right"

		if !children
			$(".secretmenu-container-back").html(container) 
		else
			$(".secretmenu-container-back").append(container)

		$(".secretmenu").eq(-1).html('<div class="secretmenu-inner">'+html+'</div>')

		$("body").addClass("secretmenu-in secretmenu-"+direction)
		$("body").attr("data-secretmenu-lvl",length)

		# Si tiene hijos
		$(".secretmenu ul li a").each ->
			if $(this).parent().find("ul").length
				if !$(this).hasClass("secretmenu-parent")
					$(this).addClass("secretmenu-parent").prepend('<i class="fa fa-chevron-right"></i>')

		# Click en item de menú
		$(".secretmenu ul li a.secretmenu-parent").unbind("click").bind "click", ->
			app.secretMenu.open "<ul>"+$(this).parent().find("ul").html()+"</ul>", true
			false

		$(".secretmenu a.secretmenu-back").unbind("click").bind "click", ->
			lastmenu = parseInt $("body").attr("data-secretmenu-lvl")
			$("body").attr("data-secretmenu-lvl",(lastmenu-1))
			$(".secretmenu.secretmenu-lvl-"+lastmenu).addClass("out")
			setTimeout ->
				$(".secretmenu.secretmenu-lvl-"+lastmenu).remove()
			,700
			false

	close: ->

		$("body").addClass("secretmenu-out")
		setTimeout ->
			$("body").removeClass "secretmenu-in secretmenu-out secretmenu-left secretmenu-right secretmenu-lvl-"+$("body").attr("data-secretmenu-lvl")
			$("body").removeAttr("data-secretmenu-lvl")
			$(".secretmenu").remove()
		,700





app.shares =

	init: ->
		$(".share").click ->
			app.shares.share $(this)

	share: (element) ->

		share_url = encodeURIComponent(element.attr("data-url"))
		share_text = encodeURIComponent(element.attr("data-text"))
		share_img = encodeURIComponent(element.attr("data-img"))

		if(element.hasClass("share-facebook"))
			app.shares.popupWindow "https://www.facebook.com/sharer/sharer.php?u="+share_url, 500, 310

		if(element.hasClass("share-twitter"))
			app.shares.popupWindow "https://twitter.com/intent/tweet?source=webclient&amp;text="+share_text+"&amp;url="+share_url, 500, 310

		if(element.hasClass("share-pinterest"))
			app.shares.popupWindow "http://pinterest.com/pin/create/button/?url="+share_url+"&media="+share_img+"&description="+share_text, 620, 310

		if(element.hasClass("share-googleplus"))
			app.shares.popupWindow "https://plus.google.com/share?url="+share_url, 500, 310

		if(element.hasClass("share-linkedin"))
			app.shares.popupWindow "http://www.linkedin.com/shareArticle?mini=true&url="+share_url+"&title="+share_text+"&summary="+share_text+"&source="+share_url, 500, 420

		false

	popupWindow: (url, w, h) ->
		left = ( $(window).width() / 2 )  - (w / 2)
		top  = ( $(window).height() / 2 ) - (h / 2)
		return window.open(url, "Compartir", 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left)




app.tooltips = ->

	$("[data-tooltip]").each ->
		pos = $(this).attr("data-tooltip-position")
		pos = "bottom" if !pos
		$(this).addClass "tooltip-parent"
		$(this).append "<span class='tooltip tooltip-"+pos+"'><span class='tooltip-container'><span class='tooltip-triangle'></span><span class='tooltip-content'>" + $(this).attr("data-tooltip") + "</span></span></span>"






app.validation =

	form: (forms,callback=false) ->

		forms.each ->

			form = $(this)

			form.find(".control .control-value").append("<div class='control-message'></div>")

			form.find("input,textarea,select").each ->
				input = $(this)				
				input.addClass( "input-"+$(this).attr("type") ) if $(this).is "input"
				input.addClass( "disabled" ) if input.is(":disabled")
				input.live "blur, change", ->
					app.validation.formInput(input)

			form.find(".input-checkbox, .input-radio").each ->
				if $(this).is(":checked")
					$(this).closest("label").addClass("checked")
				else
					$(this).closest("label").removeClass("checked")
			
			form.find(".input-checkbox, .input-radio").change ->
				form.find(".input-checkbox, .input-radio").each ->
					if $(this).is(":checked")
						$(this).closest("label").addClass("checked")
					else
						$(this).closest("label").removeClass("checked")


			form.find("input.number").each ->
				$(this).removeClass("number").wrap("<div class='number'>").after("<div class='number-button number-more'>+</div><div class='number-button number-less'>-</div>")

			form.find(".number .number-button").live "click", ->

				_input = $(this).parent().find("input")

				_max = parseInt(_input.attr("data-max"))
				_min = parseInt(_input.attr("data-min"))
				_min = 1 if !_min

				_steps = parseInt(_input.attr("data-steps"))
				_steps = 1 if !_steps

				_val = parseInt(_input.val())
				_val = _val + _steps if $(this).hasClass "number-more"
				_val = _val - _steps if $(this).hasClass "number-less"
				_val = _max if _val >= _max
				_val = _min if _val <= _min

				_input.val(_val)
				
				false

			form.find(".number input").live "blur", ->

				_input = $(this)

				_max = parseInt(_input.attr("data-max"))
				_min = parseInt(_input.attr("data-min"))
				_min = 1 if !_min

				_val = parseInt(_input.val())
				_val = _max if _val >= _max
				_val = _min if _val <= _min

				_input.val(_val)

				true



			form.submit ->

				send = true
				form = $(this) 

				form.find("input,textarea,select").each ->
					app.validation.formInput($(this),true)

				diverror = form.find(".control-error").eq(0)

				if diverror.length

					send = false
					top = diverror.offset().top - $(".header-top").height() - 25

					$("html,body").animate
						scrollTop: top

					setTimeout ->
						diverror.find("input").eq(0).focus()
					,500

				if send == true
					if callback
						callback()
						send = false

				return send


	formInput: (input,validateEmpty=false) ->

		parent = input.closest(".control-value")

		controls = input.closest(".controls")
		control  = input.closest(".control")

		fvErrors = {
			"empty": "Este campo es requerido",
			"emptySelect": "Selecciona una opción",
			"emptyRadio": "Selecciona una opción",
			"emptyCheckbox": "Selecciona al menos una opción",
			"invalidEmail": "Email inválido",
			"invalidEmailRepeat": "El email ingresado no es igual al anterior"
			"invalidPass": "La contraseña debe ser mayor a 6 carácteres"
			"invalidPassRepeat": "La contraseña no es igual a la anterior"
			"invalidRut": "RUT inválido",
			"terms": "Debes aceptar los términos legales",
		}


		if !input.hasClass("optional") && input.attr("type")!="submit" && input.attr("type")!="hidden" && input.attr("name")

			error = false
			
			if !input.val()

				# Validar si el campo se llena (opcional)
				if validateEmpty == true
					if input.is("select")
						app.validation.formInputMessage(input,fvErrors.emptySelect)
					else
						app.validation.formInputMessage(input,fvErrors.empty)
			else

				# Validar email
				if input.is("[type='email']")
					if ! app.validation.email( input, input.val() ) 
						app.validation.formInputMessage(input,fvErrors.invalidEmail)
						error = true


				# Validar contraseña
				if input.is("[type='password']")
					if input.val().length < 6
						app.validation.formInputMessage(input,fvErrors.invalidPass)
						error = true


				# Validar repetir contraseña
				if input.is("[data-repeat]")
					if input.val() != controls.find("[name='"+input.attr("data-repeat")+"']").val()
						if input.is("[type='password']")
							app.validation.formInputMessage(input,fvErrors.invalidPassRepeat)
							error = true
						if input.is("[type='email']")
							app.validation.formInputMessage(input,fvErrors.invalidEmailRepeat)
							error = true


				# Validar checkboxs/radios
				if (input.is("[type='checkbox']") || input.is("[type='radio']"))
					if !controls.find("input[name='"+input.attr("name")+"']:checked").length
						app.validation.formInputMessage(input,fvErrors.emptyCheckbox) if input.is("[type='checkbox']")
						app.validation.formInputMessage(input,fvErrors.emptyRadio)    if input.is("[type='radio']")
						app.validation.formInputMessage(input,fvErrors.terms)         if input.is(".input-terms")
						error = true
						parent.find(".control-error").removeClass("error")


				# Validar RUT
				if input.is(".rut")
					input.val( $.Rut.formatear($.Rut.quitarFormato(input.val()),$.Rut.getDigito($.Rut.quitarFormato(input.val()))) )
					if !$.Rut.validar(input.val())
						app.validation.formInputMessage(input,fvErrors.invalidRut)
						error = true

				# Si no hay errores, se quita el mensaje de error
				if error == false
					app.validation.formInputMessage(input,false)



	formInputMessage: (input,message) ->
		if message
			input.addClass("control-error")
			parent = input.closest(".control-value")
			parent.addClass("control-error")
			parent.find(".control-message").text(message).addClass("in")
		else
			input.removeClass("control-error")
			parent = input.closest(".control-value")
			parent.removeClass("control-error")	
			parent.find(".control-message").addClass("out")
			setTimeout ->
				parent.find(".control-message").removeClass("in out").text("")
			,500



	email: (elemento,valor) ->
		if /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(valor)
			return true
		else
			return false





